<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-0 ml-sm-1 ml-md-2 white--text"
        fab
        x-small
        color="green"
        @click="handleClick"
        :disabled="disabled"
        :tabindex="inputTabIndex"
        :style="style"
      >
        <v-icon>
          {{ $icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </template>
    <span v-html="title"></span>
  </v-tooltip>
</template>

<script>
import DefaultValueActionButton from './DefaultValueActionButton'

export default {
  name: 'AddDefaultValueButton',
  extends: DefaultValueActionButton
}
</script>
