<template>
  <v-form
    class="px-1 px-lg-4 py-lg-2"
    v-model="valid"
    @submit.prevent.stop="handleSubmit"
    ref="form"
  >
    <help-dialog
      path="global.components.contractSummaryForm.otherSegment.headings.general"
      tag="h2"
      css-classes="mt-4 mb-2 text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold"
    ></help-dialog>
    <bdms-control-checkbox
      field="hasTexts"
      @change="handleBdmsControlCheckboxChange"
    ></bdms-control-checkbox>
    <div
      v-if="controlData.otherSegment.hasTexts"
    >
      <div class="pl-0 pl-md-8">
        <bdms-text-input
          field="heading"
          restorable
        ></bdms-text-input>
        <bdms-multi-text-input
          field="texts"
          required
        ></bdms-multi-text-input>
      </div>
    </div>
    <next-button></next-button>
  </v-form>
</template>

<script>
import PartialFormMixin from '@/mixins/contract-summaries/partialForm.mixin'

export default {
  name: 'CreateContractSummaryOtherSegmentForm',
  mixins: [
    PartialFormMixin
  ],
  data () {
    return {
      formSegment: 'otherSegment',
      skip: false
    }
  }
}
</script>
