<template>
  <v-form
    class="px-1 px-lg-4 py-lg-2"
    v-model="valid"
    @submit.prevent.stop="handleSubmit"
    ref="form"
  >
    <help-dialog
      path="global.components.contractSummaryForm.periodSegment.headings.general"
      tag="h2"
      css-classes="mt-4 mb-6 text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold"
    ></help-dialog>
    <div class="pl-0 pl-md-8">
      <bdms-text-input
        field="heading"
        restorable
      ></bdms-text-input>
      <bdms-text-input
        field="contractTerm"
        required
      ></bdms-text-input>
      <help-dialog
        path="global.components.contractSummaryForm.periodSegment.headings.contractTermRemedyTexts"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasContractTermRemedyTexts"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.periodSegment.hasContractTermRemedyTexts"
        >
          <bdms-multi-text-input
            field="contractTermRemedyTexts"
            required
          ></bdms-multi-text-input>
        </div>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.periodSegment.headings.terminationConditionTexts"
        tag="h3"
        :css-classes="subHeadingCssClasses(['my-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-multi-text-input
          field="terminationConditionTexts"
          required
        ></bdms-multi-text-input>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.periodSegment.headings.contractExtensionTexts"
        tag="h3"
        :css-classes="subHeadingCssClasses(['my-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-multi-text-input
          field="contractExtensionTexts"
          required
        ></bdms-multi-text-input>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.periodSegment.headings.earlyTerminationTexts"
        tag="h3"
        :css-classes="subHeadingCssClasses(['my-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-multi-text-input
          field="earlyTerminationTexts"
          required
        ></bdms-multi-text-input>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.periodSegment.headings.deviceUnlockTexts"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasDeviceUnlockTexts"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.periodSegment.hasDeviceUnlockTexts"
        >
          <bdms-multi-text-input
            field="deviceUnlockTexts"
            required
          ></bdms-multi-text-input>
        </div>
      </div>
    </div>
    <next-button></next-button>
  </v-form>
</template>

<script>
import PartialFormMixin from '@/mixins/contract-summaries/partialForm.mixin'

export default {
  name: 'CreateContractSummaryPeriodSegmentForm',
  mixins: [
    PartialFormMixin
  ],
  data () {
    return {
      formSegment: 'periodSegment',
      skip: false
    }
  }
}
</script>
