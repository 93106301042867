<template>
  <v-dialog
    :value="value"
    fullscreen
    hide-overlay
    @input="close"
  >
    <v-card class="preview-card">
      <div class="preview-card__inner">
        <v-toolbar color="primary" dark class="preview-card__header">
          <v-btn
            icon
            dark
            @click="close"
          >
            <v-icon>{{ $icons.mdiClose }}</v-icon>
          </v-btn>

          <v-toolbar-title>{{ title }}</v-toolbar-title>

          <template v-if="$scopedSlots.actions">
            <v-spacer></v-spacer>

            <slot name="actions"></slot>
          </template>
        </v-toolbar>

        <div class="embedded-object embedded-object--fullscreen">
          <v-progress-circular
            v-if="!hasBlobUri"
            :size="92"
            color="primary"
            indeterminate
          ></v-progress-circular>

          <iframe
            v-if="hasBlobUri"
            :src="blobUri"
            class="embedded-object embedded-object--fullscreen flex-grow-1"
          ></iframe>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { previewContractSummaryHandle } from '@/api/contractSummaries.api'
import { transformContractSummaryFormData } from '@/helpers/templates.helper'

export default {
  name: 'RenderedPreview',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      blobUri: null
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    async loadPdf () {
      const blob = await this.getPdfAsBlob()

      this.blobUri = URL.createObjectURL(blob)
    },
    async getPdfAsBlob () {
      const data = transformContractSummaryFormData(this.formData, this.controlData)
      const { request } = await previewContractSummaryHandle(data)

      return request.response
    }
  },
  computed: {
    ...mapGetters('contractSummaries', ['formData', 'controlData']),
    hasBlobUri () {
      return this.blobUri !== null
    }
  },
  watch: {
    value (show) {
      if (show) {
        this.loadPdf()
        return
      }

      this.blobUri = null
    }
  }
}
</script>

<style scoped>
.preview-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview-card__header {
  flex-grow: 0;
}

.embedded-object {
  --embedded-object-width: auto;
  --embedded-object-height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: var(--embedded-object-width);
  height: var(--embedded-object-height);

  border: none;

  max-width: 100%;
}

.embedded-object--fullscreen {
  --embedded-object-width: 100%;
  --embedded-object-height: 100%;

  padding: 40px;
}
</style>
