import { mapGetters } from 'vuex'

export default {
  inject: [
    'formSegment'
  ],
  computed: {
    ...mapGetters('contractSummaries', ['currentSegment', 'defaultValues']),
    inputTabIndex () {
      return this.currentSegment !== this.formSegment ? -1 : 0
    }
  }
}
