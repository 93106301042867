<template>
  <div>
    <v-checkbox
      class="d-inline-block"
      v-model="value"
      :label="label"
      :tabindex="inputTabIndex"
      :disabled="disabled"
    ></v-checkbox>
  </div>
</template>

<script>
import BdmsControlBaseInput from './BdmsControlBaseInput'

export default {
  name: 'BdmsControlCheckbox',
  extends: BdmsControlBaseInput,
  computed: {
    label () {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.labels.${this.field}`)
    }
  }
}
</script>
