<template>
  <div class="d-flex flex-row flex-grow-1 align-baseline">
    <v-text-field
      ref="input"
      outlined
      v-model="value"
      :rules="rules"
      :label="label"
      :disabled="disabled"
      v-bind="maxLengthProps"
      @blur="handleBlur"
      :tabindex="inputTabIndex"
      :suffix="suffix"
      :dense="dense"
    ></v-text-field>
    <set-default-value-button
      v-if="restorable"
      :field="field"
      @clicked="setDefaultValue"
    ></set-default-value-button>
  </div>
</template>

<script>
import BdmsBaseInput from './BdmsBaseInput'

export default {
  name: 'BdmsTextInput',
  extends: BdmsBaseInput
}
</script>
