<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      v-if="apiError"
      v-text="apiError"
      class="mb-2 text-caption red--text"
    ></div>
    <div
      v-for="(uuid, index) in mapping"
      class="d-flex flex-column"
      :key="uuid"
    >
      <v-divider class="mt-4 mb-12 mt-md-2 mb-md-6" v-if="isComplex && vbp.smAndDown && index > 0"></v-divider>
      <div class="d-flex flex-grow-1 align-baseline">
        <template
          v-if="!isComplex"
        >
          <bdms-text-input
            :field="`${field}.${index}`"
            :label-path="field"
            :required="required"
            :key="`input-${uuid}`"
            :max-length="maxLength"
          ></bdms-text-input>
        </template>
        <template
          v-else
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="py-0 py-md-3"
              v-for="(_, key) in value[index]"
              :key="`value-${uuid}-${key}`"
            >
              <bdms-text-input
                :field="`${field}.${index}.${key}`"
                :label-path="`${field}.${key}`"
                :required="isRequired(key)"
                :key="`input-${uuid}-${key}`"
                :max-length="maxLength"
              ></bdms-text-input>
            </v-col>
          </v-row>
        </template>
        <remove-default-value-button
          @clicked="removeDefaultValue"
          :field="field"
          :index="index"
          :disabled="removeButtonDisabled"
          :title="removeButtonTitle"
          :key="`remove-${index}`"
        ></remove-default-value-button>
        <add-default-value-button
          @clicked="addDefaultValue"
          :field="field"
          :index="index"
          :disabled="addButtonDisabled"
          :title="addButtonTitle"
          :key="`add-${index}`"
        ></add-default-value-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BdmsBaseInput from './BdmsBaseInput'
import BdmsTextInput from './BdmsTextInput'
import AddDefaultValueButton from '../sub-components/AddDefaultValueButton'
import RemoveDefaultValueButton from '../sub-components/RemoveDefaultValueButton'

export default {
  name: 'BdmsMultiTextInput',
  extends: BdmsBaseInput,
  components: {
    BdmsTextInput,
    AddDefaultValueButton,
    RemoveDefaultValueButton
  },
  props: {
    minFields: {
      type: Number,
      default: 1
    },
    maxFields: {
      type: Number,
      default: 0
    },
    requiredFields: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['addDefaultValue', 'removeDefaultValue']),
    isRequired (field) {
      return this.requiredFields.indexOf(field) >= 0
    }
  },
  computed: {
    addButtonDisabled () {
      return this.maxFields > 0 && this.value.length >= this.maxFields
    },
    removeButtonDisabled () {
      return this.value.length <= this.minFields
    },
    addButtonTitle () {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.buttons.${this.field}.addButtonTitle`)
    },
    removeButtonTitle () {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.buttons.${this.field}.removeButtonTitle`)
    },
    isComplex () {
      return Array.isArray(this.value) && this.value.reduce((cur, val) => {
        return cur && typeof val === 'object'
      }, true)
    }
  }
}
</script>
