<template>
  <div
    class="d-flex flex-column flex-sm-row justify-space-between justify-md-end mt-4 mt-md-8 align-start align-sm-baseline pl-0 pl-md-8"
  >
    <span class="text-body-2 text-md-body-2 mr-4" v-text="mandatoryFieldsText"></span>
    <div class="mt-2 mt-sm-0 align-self-end align-self-sm-auto">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            type="submit"
            color="green"
            class="white--text"
            v-text="nextButton"
            :tabindex="inputTabIndex"
          ></v-btn>
        </template>
        <span v-html="nextButtonTooltip"></span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import FormSegmentAwareMixin from '@/mixins/contract-summaries/formSegmentAware.mixin'

export default {
  name: 'NextButton',
  mixins: [
    FormSegmentAwareMixin
  ],
  inject: [
    '$tp'
  ],
  computed: {
    mandatoryFieldsText () {
      return this.$t('global.components.contractSummaryForm.mandatoryFieldsText')
    },
    nextButton () {
      return this.$t('global.components.contractSummaryForm.nextButton')
    },
    nextButtonTooltip () {
      return this.$t('global.components.contractSummaryForm.nextButtonTooltip')
    }
  }
}
</script>
