<template>
  <div class="px-1 px-lg-4 py-2">
    <h2 class="mt-4 mb-4 text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold">{{ $t('global.components.contractSummaryForm.submitSegment.headings.preview') }}</h2>
    <div class="d-flex flex-column flex-sm-row justify-md-space-between align-md-baseline">
      <p class="mb-4" v-text="$t('global.components.contractSummaryForm.submitSegment.texts.description')"></p>
      <v-btn
        class="white--text ml-sm-4"
        color="primary"
        @click="handlePreview"
        :tabindex="inputTabIndex"
        v-html="previewTemplateButtonText"
      ></v-btn>
    </div>
    <div v-if="standalone">
      <v-divider class="my-8"></v-divider>
      <v-form
        v-model="valid"
      >
        <v-text-field
          :rules="rules"
          :label="label"
          :tabindex="inputTabIndex"
          v-model="templateName"
        ></v-text-field>
      </v-form>
      <div class="d-flex justify-end">
        <v-btn
          class="white--text"
          color="primary"
          @click="handleForm"
          :tabindex="inputTabIndex"
          :disabled="!valid"
          v-html="saveTemplateButtonText"
        ></v-btn>
      </div>
    </div>

    <rendered-preview
      v-model="preview"
      :title="previewDialogTitle"
    ></rendered-preview>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PartialFormMixin from '@/mixins/contract-summaries/partialForm.mixin'

import RenderedPreview from '../preview/RenderedPreview'

export default {
  name: 'CreateContractSummarySubmitSegmentForm',
  mixins: [
    PartialFormMixin
  ],
  components: {
    RenderedPreview
  },
  inject: [
    'standalone'
  ],
  props: {
    editTemplateName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      formSegment: 'submitSegment',
      valid: false,
      preview: false,
      editMode: this.$route.path.includes('update'),
      templateName: ''
    }
  },
  methods: {
    handleSave () {
      this.$emit('save', { templateName: this.templateName })
    },
    handleUpdate () {
      this.$emit('update', { templateName: this.templateName })
    },
    handleForm () {
      this.editMode ? this.handleUpdate() : this.handleSave()
    },
    handlePreview () {
      this.preview = true
    }
  },
  computed: {
    ...mapGetters('contractSummaries', ['currentSegment']),
    inputTabIndex () {
      return this.currentSegment === this.formSegment ? 0 : -1
    },
    previewTemplateButtonText () {
      return this.$t('global.components.contractSummaryForm.previewTemplateButton')
    },
    saveTemplateButtonText () {
      return this.editMode
        ? this.$t('global.components.contractSummaryForm.updateTemplateButton')
        : this.$t('global.components.contractSummaryForm.saveTemplateButton')
    },
    label () {
      return this.$t('global.components.contractSummaryForm.submitSegment.labels.templateName')
    },
    rules () {
      return [(v) => !!v || this.$t('global.form.errorMessages.fields.generic.required')]
    },
    previewDialogTitle () {
      return this.$t('global.components.contractSummaryForm.previewDialogTitle')
    }
  },
  watch: {
    currentSegment () {
      this.preview = false
    },
    editTemplateName: {
      handler () {
        this.templateName = this.editTemplateName
      },
      immediate: true
    }
  }
}
</script>
