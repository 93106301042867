<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash/get'
import FormSegmentAwareMixin from '@/mixins/contract-summaries/formSegmentAware.mixin'

export default {
  name: 'BdmsControlInput',
  mixins: [
    FormSegmentAwareMixin
  ],
  props: {
    field: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: [
    '$tp',
    'formSegment'
  ],
  methods: {
    ...mapActions('contractSummaries', ['setControlDataValue'])
  },
  computed: {
    ...mapGetters('contractSummaries', ['controlData']),
    value: {
      get () {
        const field = `${this.formSegment}.${this.field}`
        return get(this.controlData, field)
      },
      set (value) {
        const field = `${this.formSegment}.${this.field}`
        this.setControlDataValue({ field, value })
      }
    }
  },
  watch: {
    value (newValue) {
      this.$emit('change', { field: this.field, value: newValue })
    }
  }
}
</script>
