<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-2 ml-md-4 white--text"
        fab
        x-small
        color="primary"
        @click="handleClick"
        :tabindex="inputTabIndex"
        :style="style"
      >
        <v-icon>
          {{ $icons.mdiUndo }}
        </v-icon>
      </v-btn>
    </template>
    <span v-html="setDefaultValueButtonText"></span>
  </v-tooltip>
</template>

<script>
import FormSegmentAwareMixin from '@/mixins/contract-summaries/formSegmentAware.mixin'
import get from 'lodash/get'

export default {
  name: 'SetDefaultValueButton',
  mixins: [
    FormSegmentAwareMixin
  ],
  props: {
    field: {
      type: String,
      required: true
    }
  },
  inject: [
    'formSegment',
    'vbp'
  ],
  methods: {
    handleClick () {
      const path = `${this.formSegment}.${this.field}`
      const value = get(this.defaultValues, path, path)
      this.$emit('clicked', { path, value })
    }
  },
  computed: {
    setDefaultValueButtonText () {
      return this.$t('global.components.contractSummaryForm.setDefaultValueButton')
    },
    style () {
      return this.vbp.xs ? {
        transform: 'scale(0.8)',
        transformOrigin: 'right'
      } : {}
    }
  }
}
</script>
