<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      v-for="(uuid, index) in mapping"
      class="d-flex flex-column"
      :key="`value-${uuid}`"
    >
      <v-divider class="mt-4 mb-8" v-if="index > 0"></v-divider>
      <div class="d-flex flex-grow-1 align-baseline mb-4">
        <div class="d-flex flex-grow-1 flex-column">
          <v-row
            class="d-flex align-baseline"
          >
            <v-col
              cols="12"
            >
              <div class="d-flex flex-column">
                <bdms-text-input
                  :field="`priceTable.${index}.invoiceLine`"
                  label-path="priceTable.invoiceLine"
                  required
                ></bdms-text-input>
                <div class="d-flex flex-column pl-sm-4">
                  <bdms-control-radio-group
                    :choices="['oneTimePrice', 'monthlyRecurringPrice', 'recurringPrice']"
                    :field="`priceTable.${index}`"
                    class="mt-n2"
                    form="controlData"
                    required
                    @change="handleRadioValueChange($event, index)"
                  ></bdms-control-radio-group>
                  <v-row
                    class="mt-2 mt-md-0"
                  >
                    <v-col
                      cols="12"
                      md="4"
                      class="py-0 py-md-3"
                      v-for="(_, key) in value[index].price"
                      :key="`value-${uuid}-${key}`"
                    >
                      <bdms-text-input
                        :field="`${field}.${index}.price.${key}`"
                        :label-path="`${field}.${key}`"
                        :required="isRequired(key)"
                        :key="`input-${uuid}-${key}`"
                      ></bdms-text-input>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <remove-default-value-button
          @clicked="removeDefaultPrice"
          :field="field"
          :index="index"
          :disabled="removeButtonDisabled"
          :title="removeButtonTitle"
          :key="`remove-${index}`"
        ></remove-default-value-button>
        <add-default-value-button
          @clicked="addDefaultPrice"
          :field="field"
          :index="index"
          :disabled="addButtonDisabled"
          :title="addButtonTitle"
          :key="`add-${index}`"
        ></add-default-value-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BdmsBaseInput from './BdmsBaseInput'
import BdmsTextInput from './BdmsTextInput'
import AddDefaultValueButton from '../sub-components/AddDefaultValueButton'
import RemoveDefaultValueButton from '../sub-components/RemoveDefaultValueButton'
import BdmsControlRadioGroup from '../sub-components/BdmsControlRadioGroup'

export default {
  name: 'BdmsMultiPriceInput',
  extends: BdmsBaseInput,
  components: {
    BdmsTextInput,
    AddDefaultValueButton,
    RemoveDefaultValueButton,
    BdmsControlRadioGroup
  },
  props: {
    minFields: {
      type: Number,
      default: 1
    },
    maxFields: {
      type: Number,
      default: 0
    },
    requiredFields: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      priceType: ''
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['addDefaultPrice', 'removeDefaultPrice', 'setPriceType']),
    isRequired (field) {
      return this.requiredFields.indexOf(field) >= 0
    },
    handleRadioValueChange (event, index) {
      const priceType = event.value
      this.setPriceType({ priceType, index })
    }
  },
  computed: {
    addButtonDisabled () {
      return this.maxFields > 0 && this.value.length >= this.maxFields
    },
    removeButtonDisabled () {
      return this.value.length <= this.minFields
    },
    addButtonTitle () {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.buttons.${this.field}.addButtonTitle`)
    },
    removeButtonTitle () {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.buttons.${this.field}.removeButtonTitle`)
    }
  }
}
</script>
