import { mapActions, mapGetters } from 'vuex'
import NextButton from '@/components/contract-summaries/sub-components/NextButton'
import PageMixin from '../page.mixin'
import BdmsMultiTextInput from '@/components/contract-summaries/inputs/BdmsMultiTextInput'
import BdmsTextInput from '@/components/contract-summaries/inputs/BdmsTextInput'
import BdmsNumberInput from '@/components/contract-summaries/inputs/BdmsNumberInput'
import BdmsTextArea from '@/components/contract-summaries/inputs/BdmsTextArea'
import BdmsRadioGroup from '@/components/contract-summaries/inputs/BdmsRadioGroup'
import BdmsControlCheckbox from '@/components/contract-summaries/sub-components/BdmsControlCheckbox'

import HelpDialog from '@/components/HelpDialog'
import { scrollToElement } from '@/helpers/utility.helper'

export default {
  mixins: [
    PageMixin
  ],
  components: {
    NextButton,
    BdmsMultiTextInput,
    BdmsTextInput,
    BdmsNumberInput,
    BdmsTextArea,
    BdmsRadioGroup,
    BdmsControlCheckbox,
    HelpDialog
  },
  provide () {
    return {
      formSegment: this.formSegment
    }
  },
  inject: [
    'vbp'
  ],
  data () {
    return {
      valid: false,
      formSegment: ''
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['setDefaultValue', 'addDefaultValue', 'removeDefaultValue', 'addInitialDefaultValue']),
    handleSubmit () {
      if (this.valid) {
        this.$emit('submit')
      } else {
        this.$refs.form.validate()
        setTimeout(() => {
          this.scrollToFirstInvalidFormField()
        }, 25)
      }
    },
    scrollToFirstInvalidFormField () {
      const inputs = this.$refs.form.inputs
      const inputsWithOffsets = inputs
        .filter((input) => {
          return input.errorBucket.length > 0
        }).map((input) => {
          return {
            input: input,
            top: input.$el.getClientRects()[0].top
          }
        }).sort((a = { input: null, top: 0 }, b = { input: null, top: 0 }) => {
          return a.top - b.top
        })
      const firstInvalidInput = inputsWithOffsets[0]
      scrollToElement(firstInvalidInput.input)
    },
    getLabel (formField) {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.labels.${formField}`)
    },
    getHeading (segment) {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.headings.${segment}`)
    },
    handleBdmsControlCheckboxChange (event) {
      if (event.value) {
        const regex = /has([A-Z])/
        if (event.field.match(regex)) {
          const formFieldName = event.field.replace(regex, (a, b) => {
            return b.toLocaleLowerCase()
          })
          this.addInitialDefaultValue({ field: `${this.formSegment}.${formFieldName}` })
        }
      }
    },
    subHeadingCssClasses (additionalClasses = []) {
      const cssClasses = ['text-subtitle-1', 'text-md-h6']
      if (this.vbp.smAndUp) {
        cssClasses.push('font-weight-bold')
      }
      return cssClasses.concat(additionalClasses).join(' ')
    }
  },
  computed: {
    ...mapGetters('contractSummaries', ['controlData', 'resetCounter']),
    ...mapGetters('contractSummaries', {
      formDataComplete: 'formData'
    }),
    formData () {
      return this.formDataComplete[this.formSegment]
    },
    segmentTitle () {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.headings.general`)
    },
    mandatoryFieldValidationRule () {
      return (v) => !!v || this.$t('global.form.errorMessages.fields.generic.required')
    }
  },
  watch: {
    resetCounter () {
      const form = this.$refs.form
      if (form) {
        form.resetValidation()
      }
    }
  }
}
