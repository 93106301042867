<template>
  <div class="d-flex flex-column flex-md-row align-start">
    <span
      v-if="!noLabel"
      class="text-subtitle-1"
      :class="{'grey--text': disabled}"
      v-html="label"
    ></span>
    <v-radio-group
      ref="input"
      class="ml-4 mt-0"
      row
      v-model="value"
      :disabled="disabled"
      @change="handleChange"
      :rules="rules"
    >
      <template
        v-if="boolean"
      >
        <v-radio
          :value="true"
          :label="getLabel('true')"
          :tabindex="inputTabIndex"
        ></v-radio>
        <v-radio
          :value="false"
          :label="getLabel('false')"
          :tabindex="inputTabIndex"
        ></v-radio>
      </template>
      <template
        v-else
      >
        <v-radio
          v-for="(choice, index) in choices"
          :key="`choice-${index}`"
          :value="choice"
          :label="getLabel(choice)"
          :tabindex="inputTabIndex"
        ></v-radio>
      </template>
    </v-radio-group>
  </div>
</template>

<script>
import BdmsBaseInput from './BdmsBaseInput'

export default {
  name: 'BdmsRadioGroup',
  extends: BdmsBaseInput,
  props: {
    boolean: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getLabel (value) {
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.labels.${this.field}Radio.${value}`)
    },
    handleChange () {
      this.$emit('change', this.value)
    }
  }
}
</script>
