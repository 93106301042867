<template>
  <v-form
    class="px-1 px-lg-4 py-lg-2"
    v-model="valid"
    @submit.prevent.stop="handleSubmit"
    ref="form"
  >
    <help-dialog
      path="global.components.contractSummaryForm.serviceSegment.headings.general"
      tag="h2"
      css-classes="mt-4 mb-6 text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold"
    ></help-dialog>
    <div class="pl-0 pl-md-8">
      <bdms-text-input
        field="heading"
        restorable
      ></bdms-text-input>
      <help-dialog
        path="global.components.contractSummaryForm.serviceSegment.headings.features"
        tag="h3"
        :css-classes="subHeadingCssClasses(['my-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-multi-text-input
          field="features"
          required
          :max-fields="10"
        ></bdms-multi-text-input>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.serviceSegment.headings.devices"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasDevices"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.serviceSegment.hasDevices"
        >
          <bdms-multi-text-input
            field="devices"
            required
            :max-fields="10"
          ></bdms-multi-text-input>
        </div>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.serviceSegment.headings.volumes"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasVolumes"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.serviceSegment.hasVolumes"
        >
          <bdms-multi-text-input
            field="volumes"
            :max-fields="10"
            :required-fields="['service', 'volume']"
          ></bdms-multi-text-input>
        </div>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.serviceSegment.headings.tvChannels"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasTvChannelsText"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.serviceSegment.hasTvChannelsText"
        >
          <bdms-text-area
            field="tvChannelsText"
            required
          ></bdms-text-area>
        </div>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.serviceSegment.headings.euFairUseHeading"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasEuFairUseSegment"
        ></bdms-control-checkbox>

        <div v-if="controlData.serviceSegment.hasEuFairUseSegment">
          <bdms-radio-group
            class="mt-4"
            field="euFairUseSegment.isPossible"
            :boolean="true"
          ></bdms-radio-group>
          <bdms-radio-group
            class="mt-0"
            field="euFairUseSegment.isRestricted"
            :boolean="true"
            :disabled="!formData.euFairUseSegment.isPossible"
          ></bdms-radio-group>
          <bdms-text-area
            field="euFairUseSegment.referenceText"
            :required="formData.euFairUseSegment.isRestricted"
            :disabled="!formData.euFairUseSegment.isRestricted"
          ></bdms-text-area>
        </div>
      </div>
    </div>
    <next-button></next-button>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import PartialFormMixin from '@/mixins/contract-summaries/partialForm.mixin'

export default {
  name: 'CreateContractSummaryServiceSegmentForm',
  mixins: [
    PartialFormMixin
  ],
  data () {
    return {
      formSegment: 'serviceSegment'
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['setFormValue'])
  },
  watch: {
    'formData.euFairUseSegment.isPossible' (newValue) {
      if (!newValue) {
        this.setFormValue({ field: `${this.formSegment}.euFairUseSegment.isRestricted`, value: false })
      }
    },
    'formData.euFairUseSegment.isRestricted' (newValue) {
      if (!newValue) {
        this.setFormValue({ field: `${this.formSegment}.euFairUseSegment.referenceText`, value: '' })
      }
    }
  }
}
</script>
