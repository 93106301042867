<template>
  <v-form
    class="px-1 px-lg-4 py-lg-2"
    v-model="valid"
    @submit.prevent.stop="handleSubmit"
    ref="form"
  >
    <help-dialog
      path="global.components.contractSummaryForm.priceSegment.headings.general"
      tag="h2"
      css-classes="mt-4 mb-6 text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold"
    ></help-dialog>
    <div class="pl-0 pl-md-8">
      <bdms-text-input
        field="heading"
        restorable
      ></bdms-text-input>
      <bdms-number-input
        field="priceCycle"
        required
        :suffix="priceCycleSuffix"
        :min-value="priceCycleMin"
      ></bdms-number-input>
      <help-dialog
        path="global.components.contractSummaryForm.priceSegment.headings.priceTable"
        tag="h3"
        :css-classes="subHeadingCssClasses(['my-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <help-dialog
          path="global.components.contractSummaryForm.priceSegment.headings.priceTableNote"
          tag="h4"
          :css-classes="'mb-2'"
        ></help-dialog>
        <bdms-text-input
          field="priceTableNote"
        ></bdms-text-input>
        <bdms-multi-price-input
          field="priceTable"
          :required-fields="['price', 'pricePerCycle', 'pricePerMonth', 'validity']"
        ></bdms-multi-price-input>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.priceSegment.headings.priceVolumeTable"
        tag="h3"
        :css-classes="subHeadingCssClasses(['my-4'])"
      ></help-dialog>
      <div class="pl-0 pl-md-8">
        <bdms-control-checkbox
          field="hasPriceVolumeTable"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.priceSegment.hasPriceVolumeTable"
        >
          <bdms-multi-text-input
            field="priceVolumeTable"
            :required-fields="['service', 'price']"
          ></bdms-multi-text-input>
        </div>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.priceSegment.headings.otherPrices"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div
        class="pl-0 pl-md-8"
      >
        <bdms-control-checkbox
          field="hasOtherPrices"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.priceSegment.hasOtherPrices"
        >
          <bdms-multi-text-input
            field="otherPrices"
            required
          ></bdms-multi-text-input>
        </div>
      </div>
      <help-dialog
        path="global.components.contractSummaryForm.priceSegment.headings.priceConditions"
        tag="h3"
        :css-classes="subHeadingCssClasses(['mt-4'])"
      ></help-dialog>
      <div
        class="pl-0 pl-md-8"
      >
        <bdms-control-checkbox
          field="hasPriceConditions"
          @change="handleBdmsControlCheckboxChange"
        ></bdms-control-checkbox>
        <div
          v-if="controlData.priceSegment.hasPriceConditions"
        >
          <bdms-multi-text-input
            field="priceConditions"
            required
          ></bdms-multi-text-input>
        </div>
      </div>
    </div>
    <next-button></next-button>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PartialFormMixin from '@/mixins/contract-summaries/partialForm.mixin'
import BdmsMultiPriceInput from '../inputs/BdmsMultiPriceInput'
import get from 'lodash/get'

export default {
  name: 'CreateContractSummaryPriceSegmentForm',
  mixins: [
    PartialFormMixin
  ],
  components: {
    BdmsMultiPriceInput
  },
  data () {
    return {
      formSegment: 'priceSegment',
      priceCycleMin: 1,
      priceCycleDefault: 30
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['setFormValue', 'setControlDataValue'])
  },
  computed: {
    ...mapGetters('contractSummaries', ['formData']),
    priceCycle: {
      get () {
        const field = `${this.formSegment}.priceCycle`
        return get(this.controlData, field)
      },
      set (value) {
        const field = `${this.formSegment}.priceCycle`
        this.setControlDataValue({ field, value: parseInt(value) })
      }
    },
    priceCycleLabel () {
      return this.$t('global.components.contractSummaryForm.priceSegment.labels.priceCycle')
    },
    priceCycleSuffix () {
      return this.$t('global.units.days')
    },
    priceCycleRules () {
      return [
        (v) => !v || (v >= this.priceCycleMin || this.$t('global.form.errorMessages.fields.number.min', {
          number: this.priceCycleMin
        })),
        (v) => !!v || this.$t('global.form.errorMessages.fields.generic.required')
      ]
    }
  },
  watch: {
    'formData.priceSegment.priceCycle': {
      immediate: true,
      handler (newValue) {
        let value
        if (!isNaN(newValue) && newValue >= this.priceCycleMin) {
          value = this.$tc('global.components.contractSummaryForm.defaultValues.priceSegment.pricePerCycleHeading', newValue, {
            count: newValue
          })
        } else {
          value = this.$tc('global.components.contractSummaryForm.defaultValues.priceSegment.pricePerCycleHeading', 0)
        }
        /** @TODO: create partial form submit handler for this case */
        setTimeout(() => {
          this.setFormValue({ field: 'priceSegment.pricePerCycleHeading', value })
        }, 500)
      }
    }
  }
}
</script>
