<script>
import FormSegmentAwareMixin from '@/mixins/contract-summaries/formSegmentAware.mixin'

export default {
  name: 'DefaultValueActionButton',
  mixins: [
    FormSegmentAwareMixin
  ],
  inject: [
    'formSegment',
    'vbp'
  ],
  props: {
    index: {
      type: Number,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick () {
      const field = `${this.formSegment}.${this.field}`
      this.$emit('clicked', { field, index: this.index })
    }
  },
  computed: {
    style () {
      return this.vbp.xs ? {
        transform: 'scale(0.8)',
        transformOrigin: 'right'
      } : {}
    }
  }
}
</script>
