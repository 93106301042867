<template>
  <v-form
    class="px-1 px-lg-4 py-lg-2"
    v-model="valid"
    @submit.prevent.stop="handleSubmit"
    ref="form"
  >
    <help-dialog
      path="global.components.contractSummaryForm.bandwidthSegment.headings.general"
      tag="h2"
      css-classes="mt-4 mb-2 text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold"
    ></help-dialog>
    <div class="pl-0 pl-md-8">
      <bdms-control-checkbox
        field="hasBandwidthSegment"
      ></bdms-control-checkbox>
      <div v-if="controlData.bandwidthSegment.hasBandwidthSegment">
        <bdms-text-input
          field="heading"
          restorable
        ></bdms-text-input>
        <help-dialog
          path="global.components.contractSummaryForm.bandwidthSegment.headings.landlineBandwidth"
          tag="h3"
          :css-classes="subHeadingCssClasses(['mt-4'])"
        ></help-dialog>
        <div class="pl-0 pl-md-8">
          <bdms-control-checkbox
            field="hasLandlineBandwidth"
            :disabled="!controlData.bandwidthSegment.hasMobileBandwidth"
          ></bdms-control-checkbox>
          <div v-if="controlData.bandwidthSegment.hasLandlineBandwidth">
            <v-row>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="landlineBandwidth.0.download"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="landlineBandwidth.0.upload"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="landlineBandwidth.1.download"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="landlineBandwidth.1.upload"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="landlineBandwidth.2.download"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="landlineBandwidth.2.upload"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
            </v-row>
          </div>
        </div>
        <help-dialog
          path="global.components.contractSummaryForm.bandwidthSegment.headings.mobileBandwidth"
          tag="h3"
          :css-classes="subHeadingCssClasses(['mt-4'])"
        ></help-dialog>
        <div class="pl-0 pl-md-8">
          <bdms-control-checkbox
            field="hasMobileBandwidth"
            :disabled="!controlData.bandwidthSegment.hasLandlineBandwidth"
          ></bdms-control-checkbox>
          <div v-if="controlData.bandwidthSegment.hasMobileBandwidth">
            <v-row>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="mobileBandwidth[0].download"
                  label-path="mobileBandwidth.download"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-2 py-md-3">
                <bdms-text-input
                  field="mobileBandwidth[0].upload"
                  label-path="mobileBandwidth.upload"
                  required
                  :max-length="32"
                ></bdms-text-input>
              </v-col>
            </v-row>
          </div>
        </div>
        <help-dialog
          path="global.components.contractSummaryForm.bandwidthSegment.headings.remedyText"
          tag="h3"
          :css-classes="subHeadingCssClasses(['my-4'])"
        ></help-dialog>
        <div class="pl-0 pl-md-8">
          <bdms-text-area
            field="remedyText"
            restorable
            required
          ></bdms-text-area>
        </div>
      </div>
    </div>
    <next-button></next-button>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import PartialFormMixin from '@/mixins/contract-summaries/partialForm.mixin'

export default {
  name: 'CreateContractSummaryBandwidthSegmentForm',
  mixins: [
    PartialFormMixin
  ],
  data () {
    return {
      formSegment: 'bandwidthSegment'
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['setFormValue'])
  }
}
</script>
