<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash/get'
import SetDefaultValueButton from '../sub-components/SetDefaultValueButton'
import FormSegmentAwareMixin from '@/mixins/contract-summaries/formSegmentAware.mixin'

export default {
  name: 'BdmsBaseInput',
  mixins: [
    FormSegmentAwareMixin
  ],
  components: {
    SetDefaultValueButton
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    restorable: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      required: true
    },
    labelPath: {
      type: String,
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 0
    },
    minValue: {
      type: Number,
      default: null
    },
    suffix: {
      type: String,
      default: ''
    }
  },
  inject: [
    '$tp',
    'formSegment',
    'vbp'
  ],
  methods: {
    ...mapActions('contractSummaries', ['setFormValue', 'setDefaultValue']),
    handleBlur () {
      if (typeof this.value === 'string') {
        this.value = this.value
          .replaceAll(/\s+/g, ' ')
          .trim()
      }
    }
  },
  computed: {
    ...mapGetters('contractSummaries', ['formData', 'controlData', 'errorData', 'currentSegment', 'labelParams']),
    value: {
      get () {
        const field = `${this.formSegment}.${this.field}`
        return get(this.formData, field)
      },
      set (value) {
        const field = `${this.formSegment}.${this.field}`
        this.setFormValue({ field, value, skipReset: this.isBdmsMultiTextInputChild })
      }
    },
    mapping () {
      const mappingField = `${this.formSegment}.${this.field}Map`
      return get(this.controlData, mappingField)
    },
    rules () {
      const rules = []
      if (this.required) {
        rules.push((v) => !!v || this.$t('global.form.errorMessages.fields.generic.required'))
      }
      if (this.maxLength > 0) {
        rules.push((v) => (v === undefined || v.length <= this.maxLength) || this.$t('global.form.errorMessages.fields.text.max', {
          length: this.maxLength
        }))
      }
      if (this.minValue !== null) {
        rules.push((v) => v >= this.minValue || this.$t('global.form.errorMessages.fields.number.min', {
          number: this.minValue
        }))
      }
      if (this.apiError !== '') {
        rules.push(() => this.apiError)
      }
      return rules
    },
    label () {
      const labelPath = this.labelPath ? this.labelPath : this.field
      if (this.labelParamsComputed !== null) {
        return this.$tc(`global.components.contractSummaryForm.${this.formSegment}.labels.${labelPath}`, this.labelParamsComputed.count, this.labelParamsComputed)
      }
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.labels.${labelPath}`)
    },
    labelParamsComputed () {
      /** @TODO: make this work for all kinds of inputs */
      const labelPath = this.labelPath ? this.labelPath : this.field
      const lb = get(this.labelParams, [this.formSegment, ...labelPath.split('.')], null)
      if (lb !== null) {
        const labelParams = {}
        Object.keys(lb).forEach((param) => {
          const value = lb[param]
          const formValue = parseInt(get(this[value.origin], value.path))
          labelParams[param] = !isNaN(formValue) ? Math.max(formValue, 0) : 0
        })
        return labelParams
      }
      return null
    },
    maxLengthProps () {
      if (this.maxLength > 0) {
        return {
          maxlength: this.maxLength,
          counter: true
        }
      }
      return {}
    },
    inputTabIndex () {
      return this.formSegment === this.currentSegment ? 0 : -1
    },
    apiError () {
      if (this.$parent.$options._componentTag !== 'bdms-multi-text-input') {
        return get(this.errorData, [this.formSegment, ...this.field.split('.'), 'message'], '')
      }
      return ''
    },
    isBdmsMultiTextInputChild () {
      return this.$parent.$options._componentTag === 'bdms-multi-text-input'
    },
    dense () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    disabled () {
      this.$refs.input.resetValidation()
    },
    apiError (newValue) {
      if (newValue !== '') {
        if (this.$refs.input) {
          this.$refs.input.validate()
        }
      }
    }
  }
}
</script>
